<!--
 * @Author: cyy
 * @version: 1.4.17
 * @Date: 2022-07-28 12:03:04  
 * @LastEditTime: 2022-08-30 18:48:12
 * @LastEditors: cyy
 * @Description: 新建超级会员
 * @FilePath: \teacherdingding.com\src\views\Survey\manageSuperMember\components\newSupermember.vue
-->
<template>
  <div class="newSupermember">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="140px"
      class="ruleFormclass"
      label-position="left"
    >
      <div class="titletop">基本信息</div>
      <el-form-item label="会员名称" :required="true">
        <el-input
          v-model="ruleForm.name"
          @blur="ruleForm.name = ruleForm.name.replace(/^\s+|\s+$/gm, '')"
          style="width:500px"
          size="medium"
          placeholder="输入会员名称"
          show-word-limit
          maxlength="10"
        ></el-input>
      </el-form-item>

      <el-form-item label="副标题" class="norequire">
        <el-input
          v-model="ruleForm.subtitle"
          @blur="
            ruleForm.subtitle = ruleForm.subtitle.replace(/^\s+|\s+$/gm, '')
          "
          style="width:500px"
          size="medium"
          placeholder="输入副标题"
          show-word-limit
          maxlength="20"
        ></el-input>
      </el-form-item>

      <el-form-item label="有效期" :required="true">
        <el-radio-group v-model="ruleForm.validityType">
          <el-radio label="1">
            长期有效（3650天）
          </el-radio>
          <el-radio label="2">
            <div style="color:#333333">
              自定义天数
              <el-input-number
                :disabled="ruleForm.validityType != 2"
                class="ml10 mr10 "
                v-model="ruleForm.validity"
                controls-position="right"
                size="medium"
                placeholder="输入天数"
                style="width:136px"
                :step="1"
                :min="1"
                :max="3650"
                :step-strictly="true"
                :precision="0"
              ></el-input-number>
              天
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="申请表单项" :required="true">
        <el-radio-group v-model="ruleForm.is_survey">
          <el-radio label="1">
            开启
          </el-radio>
          <el-radio label="2">
            关闭
          </el-radio>
        </el-radio-group>

        <!--表单提交-->
        <div class="form-box" v-if="ruleForm.is_survey == 1">
          <div
            class="form-item mb20"
            v-for="(item, index) in ruleForm.questionList"
            :key="item.id"
          >
            <div class="flex-center">
              <div class="left">
                <div class="flex-center">
                  <div class="required">
                    <template v-if="item.not_null == 1">*</template>
                  </div>
                  <div class="title">{{ item.qname }}</div>
                  <template v-if="'is_sync' in item">
                    <el-switch
                      class="dx-switch"
                      :value="item.is_sync"
                      active-value="1"
                      inactive-value="2"
                      @change="item.is_sync = arguments[0]"
                    ></el-switch>
                    <div class="title ml10">
                      同步到学员详情
                    </div>
                  </template>
                  <template v-if="'is_verification' in item">
                    <el-switch
                      class="dx-switch"
                      :value="item.is_verification"
                      active-value="1"
                      inactive-value="2"
                      @change="item.is_verification = arguments[0]"
                    ></el-switch>
                    <div class="title ml10">
                      短信验证{{
                        item.is_verification == 1 ? '已开启' : '已关闭'
                      }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="right flex-center btns">
                <el-checkbox
                  :value="item.not_null"
                  true-label="1"
                  false-label="2"
                  style="margin-top: 2px;"
                  @change="item.not_null = arguments[0]"
                >
                  必填
                </el-checkbox>
                <div class="space mr20"></div>
                <el-button
                  type="text"
                  :disabled="item.qattr == 1"
                  @click="edit(item)"
                >
                  编辑
                </el-button>
                <div class="space">|</div>
                <el-button
                  type="text"
                  :disabled="index == 0"
                  @click="upDownMove(index, index - 1)"
                >
                  上移
                </el-button>
                <div class="space">|</div>
                <el-button
                  type="text"
                  :disabled="index == ruleForm.questionList.length - 1"
                  @click="upDownMove(index, index + 1)"
                >
                  下移
                </el-button>
                <div class="space">|</div>
                <template v-if="'is_hide' in item">
                  <el-popconfirm
                    v-if="item.is_hide == 2"
                    title="确定隐藏此选项？"
                    @confirm="changehiden(index, 1)"
                  >
                    <el-button slot="reference" type="text">
                      隐藏
                    </el-button>
                  </el-popconfirm>
                  <el-button v-else @click="changehiden(index, 2)" type="text">
                    显示
                  </el-button>
                </template>
                <el-popconfirm
                  v-else
                  title="确定删除此选项？"
                  @confirm="removeQuestion(index)"
                >
                  <el-button
                    slot="reference"
                    type="text"
                    :disabled="item.qattr == 1"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            </div>
            <!--单选样式1-->
            <template v-if="item.style == 1">
              <div
                class="option1"
                v-for="(v, index) in item.option_content"
                :key="index"
              >
                {{ v }}
              </div>
            </template>
            <!--单选样式2-->
            <template v-if="item.style == 2">
              <el-select v-model="style2" class="option2">
                <el-option
                  v-for="(v, index) in item.option_content"
                  :key="index"
                  :label="v"
                  :value="v"
                ></el-option>
              </el-select>
            </template>
          </div>
          <!--填空输入框-->
          <div class="flex-center form-input" v-if="customForm">
            <el-input
              ref="questionInput"
              v-model.trim="question.qname"
              @keyup.enter.native="saveQuestion"
              placeholder="请输入表单项题目，20字以内，回车键保存"
            ></el-input>
            <div class="flex-center">
              <el-checkbox
                :value="question.not_null"
                :checked="question.not_null == 1"
                true-label="1"
                false-label="2"
                style="margin-right: 140px;"
                @change="question.not_null = arguments[0]"
              >
                必填
              </el-checkbox>
              <el-button type="text" @click="saveQuestion">保存</el-button>
              <el-button type="text" @click="toggleCustomForm1">取消</el-button>
            </div>
          </div>
          <!--默认添加 单选 填空-->
          <div class="flex-center form-btn" v-else>
            + 自定义表单：
            <el-button type="text" @click="toggleCustomForm1">
              填空
            </el-button>
            <div class="space">|</div>
            <el-button type="text" @click="toggleCustomForm2">
              单选
            </el-button>
          </div>
        </div>
      </el-form-item>

      <div class="titletop">会员权益</div>
      <el-form-item label="购买权益" class="norequire">
        <el-radio-group v-model="ruleForm.is_buy_equity">
          <el-radio label="1">
            开启
          </el-radio>
          <el-radio label="2">
            关闭
          </el-radio>
        </el-radio-group>
        <el-form v-if="ruleForm.is_buy_equity == 1">
          <el-form-item label="权益名称" :required="true">
            <el-input
              v-model="ruleForm.buy_equity_name"
              @blur="
                ruleForm.buy_equity_name = ruleForm.buy_equity_name.replace(
                  /^\s+|\s+$/gm,
                  ''
                )
              "
              style="width:500px"
              size="medium"
              placeholder="仅会员可用"
              show-word-limit
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="适用商品" :required="true">
            <el-radio-group
              v-model="ruleForm.buy_equity_type"
              @change="equityTypeChange(1)"
            >
              <el-radio label="1">
                全部商品
              </el-radio>
              <el-radio label="2">
                部分商品
              </el-radio>
            </el-radio-group>
            <div class="forgood" v-if="ruleForm.buy_equity_type == 2">
              <div class="flex-center mt20 mb20">
                <el-button
                  type="primary"
                  @click="bugFreeCourse(1)"
                  class="mr10"
                  size="medium"
                >
                  + 添加商品
                </el-button>
                <!-- <div class="info2">{{ goodsList.length }}/100</div> -->
                <div class="redcolor">
                  <el-button
                    class=" ml20"
                    type="text"
                    @click="ruleForm.buyGoodsList = []"
                  >
                    一键清空
                  </el-button>
                </div>
              </div>
              <div class="good_contain">
                <el-table
                  v-if="ruleForm.buyGoodsList.length > 0"
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                  :data="
                    ruleForm.buyGoodsList.slice(
                      (currentPage1 - 1) * 5,
                      (currentPage1 - 1) * 5 + 5
                    )
                  "
                  style="width: 700px;"
                >
                  <el-table-column label="商品" width="260">
                    <template slot-scope="{ row }">
                      <div class="contain">
                        <div class="tu">
                          <img
                            :src="row.cover_img || row.photo || row.img_url"
                            alt=""
                          />
                        </div>
                        <div class="right">
                          <div class="itemtitle">{{ row.name }}</div>
                          <div class="msg">
                            <div class="flex-center">
                              <template v-if="row.price">
                                <div class="money">￥{{ row.price }}</div>
                                <div
                                  class="cross_money"
                                  v-if="
                                    (row.crossed_price &&
                                      row.crossed_price > 0) ||
                                      (row.costprice && row.costprice > 0) ||
                                      (row.cost_price && row.cost_price > 0)
                                  "
                                >
                                  ￥
                                  {{
                                    row.crossed_price ||
                                      row.costprice ||
                                      row.cost_price
                                  }}
                                </div>
                              </template>
                              <template v-if="row.member_id">
                                <div class="type" v-if="'is_show' in row">
                                  <!-- 1展示 2不展示 -->
                                  <img
                                    src="~@ass/img/1.3.9.4/icon_dg.png"
                                    alt=""
                                    v-if="row.is_show == 1"
                                  />
                                  <img
                                    src="~@ass/img/1.3.9.4/icon_cw.png"
                                    alt=""
                                    v-else
                                  />

                                  <span v-if="row.is_show == 1">
                                    展示到网校
                                  </span>
                                  <span v-else>不展示到网校</span>

                                  <!-- 1已上架  2已下架   -->
                                  <img
                                    v-if="row.status == 1"
                                    class="ml20"
                                    src="~@ass/img/1.3.9.4/icon_dg.png"
                                    alt=""
                                  />
                                  <img
                                    v-else
                                    class="ml20"
                                    src="~@ass/img/1.3.9.4/icon_cw.png"
                                    alt=""
                                  />

                                  <span v-if="row.status == 1">已启用</span>
                                  <span v-else>已停用</span>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型">
                    <template slot-scope="{ row }">
                      {{ row.good_type | getGoodtext }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="number" label="指定分组">
                    <template slot-scope="{ row }">
                      <template v-if="row.groups && row.groups.length > 0">
                        <p v-for="item in row.groups" :key="item.gid">
                          {{ item.gname }}
                        </p>
                      </template>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" width="180">
                    <template slot-scope="{ $index }">
                      <el-button type="text" @click="setgroup($index, 1)">
                        指定分组
                      </el-button>
                      <el-divider direction="vertical"></el-divider>
                      <el-popconfirm
                        title="确定移除该商品？"
                        @confirm="delCourse($index, 1)"
                      >
                        <el-button slot="reference" type="text">
                          移除
                        </el-button>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>

                <el-pagination
                  v-if="ruleForm.buyGoodsList.length > 0"
                  :pager-count="5"
                  :current-page.sync="currentPage1"
                  :page-size="5"
                  layout="prev, pager, next, jumper"
                  :total="ruleForm.buyGoodsList.length"
                ></el-pagination>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-form-item>

      <el-form-item label="免费权益" class="norequire">
        <el-radio-group v-model="ruleForm.is_free_equity">
          <el-radio label="1">
            开启
          </el-radio>
          <el-radio label="2">
            关闭
          </el-radio>
        </el-radio-group>
        <el-form v-if="ruleForm.is_free_equity == 1">
          <el-form-item label="权益名称" :required="true">
            <el-input
              v-model="ruleForm.free_equity_name"
              @blur="
                ruleForm.free_equity_name = ruleForm.free_equity_name.replace(
                  /^\s+|\s+$/gm,
                  ''
                )
              "
              style="width:500px"
              size="medium"
              placeholder="请输入名称"
              show-word-limit
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="适用商品" :required="true">
            <el-radio-group
              v-model="ruleForm.free_equity_type"
              @change="equityTypeChange(2)"
            >
              <el-radio label="1">
                全部商品
              </el-radio>
              <el-radio label="2">
                部分商品
              </el-radio>
            </el-radio-group>
            <div class="forgood" v-if="ruleForm.free_equity_type == 2">
              <div class="flex-center mt20 mb20">
                <el-button
                  type="primary"
                  @click="bugFreeCourse(2)"
                  class="mr10"
                  size="medium"
                >
                  + 添加商品
                </el-button>
                <div class="redcolor">
                  <el-button
                    class="ml20"
                    type="text"
                    @click="ruleForm.freeGoodsList = []"
                  >
                    一键清空
                  </el-button>
                </div>
              </div>
              <div class="good_contain">
                <el-table
                  v-if="ruleForm.freeGoodsList.length > 0"
                  :header-cell-style="{
                    background: 'rgba(245,245,245,1)',
                    color: '#333333',
                  }"
                  :data="
                    ruleForm.freeGoodsList.slice(
                      (currentPage2 - 1) * 5,
                      (currentPage2 - 1) * 5 + 5
                    )
                  "
                  style="width: 700px;"
                >
                  <el-table-column label="商品" width="260">
                    <template slot-scope="{ row }">
                      <div class="contain">
                        <div class="tu">
                          <img
                            :src="row.cover_img || row.photo || row.img_url"
                            alt=""
                          />
                        </div>
                        <div class="right">
                          <div class="itemtitle">{{ row.name }}</div>
                          <div class="msg">
                            <div class="flex-center">
                              <template v-if="row.price">
                                <div class="money">￥{{ row.price }}</div>
                                <div
                                  class="cross_money"
                                  v-if="
                                    (row.crossed_price &&
                                      row.crossed_price > 0) ||
                                      (row.costprice && row.costprice > 0) ||
                                      (row.cost_price && row.cost_price > 0)
                                  "
                                >
                                  ￥
                                  {{
                                    row.crossed_price ||
                                      row.costprice ||
                                      row.cost_price
                                  }}
                                </div>
                              </template>
                              <template v-if="row.member_id">
                                <div class="type" v-if="'is_show' in row">
                                  <!-- 1展示 2不展示 -->
                                  <img
                                    src="~@ass/img/1.3.9.4/icon_dg.png"
                                    alt=""
                                    v-if="row.is_show == 1"
                                  />
                                  <img
                                    src="~@ass/img/1.3.9.4/icon_cw.png"
                                    alt=""
                                    v-else
                                  />

                                  <span v-if="row.is_show == 1">
                                    展示到网校
                                  </span>
                                  <span v-else>不展示到网校</span>

                                  <!-- 1已上架  2已下架   -->
                                  <img
                                    v-if="row.status == 1"
                                    class="ml20"
                                    src="~@ass/img/1.3.9.4/icon_dg.png"
                                    alt=""
                                  />
                                  <img
                                    v-else
                                    class="ml20"
                                    src="~@ass/img/1.3.9.4/icon_cw.png"
                                    alt=""
                                  />

                                  <span v-if="row.status == 1">已启用</span>
                                  <span v-else>已停用</span>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="类型">
                    <template slot-scope="{ row }">
                      {{ row.good_type | getGoodtext }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="number" label="指定分组">
                    <template slot-scope="{ row }">
                      <template v-if="row.groups.length > 0">
                        <p v-for="item in row.groups" :key="item.gid">
                          {{ item.gname }}
                        </p>
                      </template>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" width="180">
                    <template slot-scope="{ $index }">
                      <el-button type="text" @click="setgroup($index, 2)">
                        指定分组
                      </el-button>
                      <el-divider direction="vertical"></el-divider>
                      <el-popconfirm
                        title="确定移除该商品？"
                        @confirm="delCourse($index, 2)"
                      >
                        <el-button slot="reference" type="text">
                          移除
                        </el-button>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  v-if="ruleForm.freeGoodsList.length > 0"
                  :pager-count="5"
                  :current-page.sync="currentPage2"
                  :page-size="5"
                  layout="prev, pager, next, jumper"
                  :total="ruleForm.freeGoodsList.length"
                ></el-pagination>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </el-form-item>
      <div class="titletop">展示设置</div>
      <el-form-item label="整体风格" :required="true">
        <div class="spec">
          <div
            class="sp_item"
            v-for="item in spec"
            :key="item.id"
            @click="selectSpec(item)"
          >
            <img :src="item.bgImg" alt="" />
            <div class="selected">
              <img
                src="@ass/img/1.4.17/icon_dg.png"
                alt=""
                v-if="selectSpecList.id == item.id"
              />
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="会员封面" :required="true">
        <el-radio-group v-model="ruleForm.cover_type" @change="changcoverType">
          <el-radio label="1">
            跟随所选风格
          </el-radio>
          <el-radio label="2">
            自定义图片
          </el-radio>
        </el-radio-group>
        <div class="coustom_contain" v-if="ruleForm.cover_type == 2">
          <div class="tu">
            <img v-if="ruleForm.cover_img" :src="ruleForm.cover_img" alt="" />
          </div>
          <div class="right">
            <el-button size="medium" style="width:100px" @click="upload">
              上传
            </el-button>
            <div class="tip">
              建议尺寸800*450px或16:9， JPG、PNG格式，图片小于2M
            </div>
          </div>
        </div>
        <changePhotoimg
          :isshow="true"
          ref="changePhotoimg"
          @complete="complete"
          class="changePhotoimg"
          :size="2"
        ></changePhotoimg>
      </el-form-item>

      <el-form-item label="展示内容" class="norequire">
        <el-checkbox-group v-model="ruleForm.display_content">
          <el-checkbox label="1">会员卡名称</el-checkbox>
          <el-checkbox label="2">副标题</el-checkbox>
          <el-checkbox label="3">权益名称</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item
        label="购买权益商品展示"
        class="norequire"
        v-if="ruleForm.is_buy_equity == 1"
      >
        <div class="flex-center mb20">
          <el-button
            type="primary"
            @click="showCourse(1)"
            style="width:130px"
            size="medium"
          >
            +添加商品
          </el-button>
          <div class="numbertip">最多添加10个商品</div>
        </div>
        <el-table
          v-if="ruleForm.buyProductsList.length > 0"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="ruleForm.buyProductsList"
          style="width: 700px;"
        >
          <el-table-column label="商品" width="260">
            <template slot-scope="{ row }">
              <div class="contain">
                <div class="tu">
                  <img
                    :src="row.cover_img || row.photo || row.img_url"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="itemtitle">{{ row.name }}</div>
                  <div class="msg">
                    <div class="flex-center">
                      <template v-if="row.price">
                        <div class="money">￥{{ row.price }}</div>
                        <div
                          class="cross_money"
                          v-if="
                            (row.crossed_price && row.crossed_price > 0) ||
                              (row.costprice && row.costprice > 0) ||
                              (row.cost_price && row.cost_price > 0)
                          "
                        >
                          ￥
                          {{
                            row.crossed_price || row.costprice || row.cost_price
                          }}
                        </div>
                      </template>
                      <template v-if="row.member_id">
                        <div class="type" v-if="'is_show' in row">
                          <!-- 1展示 2不展示 -->
                          <img
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                            v-if="row.is_show == 1"
                          />
                          <img
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                            v-else
                          />

                          <span v-if="row.is_show == 1">
                            展示到网校
                          </span>
                          <span v-else>不展示到网校</span>

                          <!-- 1已上架  2已下架   -->
                          <img
                            v-if="row.status == 1"
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />

                          <span v-if="row.status == 1">已启用</span>
                          <span v-else>已停用</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="{ row }">
              {{ row.good_type | getGoodtext }}
            </template>
          </el-table-column>

          <el-table-column prop="address" label="操作" width="180">
            <template slot-scope="{ $index }">
              <el-button
                type="text"
                :disabled="$index == 0"
                @click="buyProductsUpDown($index, $index - 1, 1)"
              >
                上移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                @click="buyProductsUpDown($index, $index + 1, 1)"
                :disabled="$index == ruleForm.buyProductsList.length - 1"
              >
                下移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                title="确定移除该商品？"
                @confirm="delbuyProducts($index, 1)"
              >
                <el-button slot="reference" type="text">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item
        label="免费权益商品展示"
        class="norequire"
        v-if="ruleForm.is_free_equity == 1"
      >
        <div class="flex-center mb20">
          <el-button
            type="primary"
            @click="showCourse(2)"
            style="width:130px"
            size="medium"
          >
            +添加商品
          </el-button>
          <div class="numbertip">最多添加10个商品</div>
        </div>
        <el-table
          v-if="ruleForm.freeProductsList.length > 0"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="ruleForm.freeProductsList"
          style="width: 700px;"
        >
          <el-table-column label="商品" width="260">
            <template slot-scope="{ row }">
              <div class="contain">
                <div class="tu">
                  <img
                    :src="row.cover_img || row.photo || row.img_url"
                    alt=""
                  />
                </div>
                <div class="right">
                  <div class="itemtitle">{{ row.name }}</div>
                  <div class="msg">
                    <div class="flex-center">
                      <template v-if="row.price">
                        <div class="money">￥{{ row.price }}</div>
                        <div
                          class="cross_money"
                          v-if="
                            (row.crossed_price && row.crossed_price > 0) ||
                              (row.costprice && row.costprice > 0) ||
                              (row.cost_price && row.cost_price > 0)
                          "
                        >
                          ￥
                          {{
                            row.crossed_price || row.costprice || row.cost_price
                          }}
                        </div>
                      </template>
                      <template v-if="row.member_id">
                        <div class="type" v-if="'is_show' in row">
                          <!-- 1展示 2不展示 -->
                          <img
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                            v-if="row.is_show == 1"
                          />
                          <img
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                            v-else
                          />

                          <span v-if="row.is_show == 1">
                            展示到网校
                          </span>
                          <span v-else>不展示到网校</span>

                          <!-- 1已上架  2已下架   -->
                          <img
                            v-if="row.status == 1"
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_dg.png"
                            alt=""
                          />
                          <img
                            v-else
                            class="ml20"
                            src="~@ass/img/1.3.9.4/icon_cw.png"
                            alt=""
                          />

                          <span v-if="row.status == 1">已启用</span>
                          <span v-else>已停用</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="{ row }">
              {{ row.good_type | getGoodtext }}
            </template>
          </el-table-column>

          <el-table-column prop="address" label="操作" width="180">
            <template slot-scope="{ $index }">
              <el-button
                type="text"
                :disabled="$index == 0"
                @click="buyProductsUpDown($index, $index - 1, 2)"
              >
                上移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                @click="buyProductsUpDown($index, $index + 1, 2)"
                :disabled="$index == ruleForm.freeProductsList.length - 1"
              >
                下移
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-popconfirm
                title="确定移除该商品？"
                @confirm="delbuyProducts($index, 2)"
              >
                <el-button slot="reference" type="text">
                  移除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <div class="titletop">会员说明</div>
      <el-form-item label="权益说明" class="norequire">
        <editor class="editor" v-model="ruleForm.equity_explain"></editor>
      </el-form-item>

      <el-form-item label="会员说明" class="norequire">
        <editor class="editor" v-model="ruleForm.member_explain"></editor>
      </el-form-item>

      <el-form-item label="使用说明" class="norequire">
        <editor class="editor" v-model="ruleForm.notice_explain"></editor>
      </el-form-item>
    </el-form>

    <!-- 展示效果 -->
    <div class="student_views">
      <img src="@ass/img/1.4.17/img_sjmx.png" alt="" />
      <div class="contain">
        <div class="top">
          <img :src="selectSpecList.bgImg" alt="" class="img" />
          <div
            class="name_subtitle"
            :style="`background:${selectSpecList.bgColor}`"
          >
            <img
              v-if="selectSpecList.coumstImg"
              :src="selectSpecList.coumstImg"
              alt=""
              class="img"
            />
            <div class="name  ">
              <div
                style="font-size:15px"
                v-if="ruleForm.display_content.some(item => item == 1)"
                :title="ruleForm.name"
                class="title hidetext"
                :style="`color:${selectSpecList.titleC}`"
              >
                {{ ruleForm.name }}
              </div>
              <div
                v-if="ruleForm.display_content.some(item => item == 2)"
                :title="ruleForm.subtitle"
                class="sub_title hidetext"
                :style="`color:${selectSpecList.subtitleC}`"
              >
                {{ ruleForm.subtitle }}
              </div>
              <div
                v-if="ruleForm.display_content.some(item => item == 3)"
                class="quanyi fs12 hidetext"
                :style="`color:${selectSpecList.equityC}`"
              >
                <p
                  style="margin-bottom:3px"
                  v-if="ruleForm.buy_equity_name && ruleForm.is_buy_equity == 1"
                >
                  ·{{ ruleForm.buy_equity_name }}
                </p>
                <p
                  v-if="
                    ruleForm.free_equity_name && ruleForm.is_free_equity == 1
                  "
                >
                  ·{{ ruleForm.free_equity_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="produce_show">
          <div
            class="prod_contain"
            v-if="
              ruleForm.is_buy_equity == 1 && ruleForm.buyProductsList.length > 0
            "
          >
            <div class="top_title">
              <div class="line"></div>
              <div class="tip">{{ ruleForm.buy_equity_name }}</div>
              <div class="line"></div>
            </div>
            <div class="list">
              <div
                class="item"
                v-for="item in ruleForm.buyProductsList"
                :key="item.id"
              >
                <img
                  class="img"
                  :src="item.cover_img || item.photo || item.img_url"
                  alt=""
                />
                <div class="course_name hidetext">{{ item.name }}</div>
                <div class="type">{{ item.good_type | getGoodtext }}</div>
              </div>
            </div>
          </div>
          <div
            class="prod_contain "
            v-if="
              ruleForm.is_free_equity == 1 &&
                ruleForm.freeProductsList.length > 0
            "
          >
            <div class="top_title">
              <div class="line"></div>
              <div class="tip">{{ ruleForm.free_equity_name }}</div>
              <div class="line"></div>
            </div>
            <div class="list">
              <div
                class="item"
                v-for="item in ruleForm.freeProductsList"
                :key="item.id"
              >
                <img
                  class="img"
                  :src="item.cover_img || item.photo || item.img_url"
                  alt=""
                />
                <div class="course_name hidetext">{{ item.name }}</div>
                <div class="type">{{ item.good_type | getGoodtext }}</div>
              </div>
            </div>
          </div>
          <div class="prod_contain" v-if="ruleForm.equity_explain">
            <div class="top_title">
              <div class="line"></div>
              <div class="tip">权益说明</div>
              <div class="line"></div>
            </div>
            <div class="editor-html" v-html="ruleForm.equity_explain"></div>
          </div>
          <div class="prod_contain " v-if="ruleForm.member_explain">
            <div class="top_title">
              <div class="line"></div>
              <div class="tip">会员说明</div>
              <div class="line"></div>
            </div>
            <div class="editor-html" v-html="ruleForm.member_explain"></div>
          </div>
          <div class="prod_contain " v-if="ruleForm.notice_explain">
            <div class="top_title">
              <div class="line"></div>
              <div class="tip">使用说明</div>
              <div class="line"></div>
            </div>
            <div class="editor-html" v-html="ruleForm.notice_explain"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提交 -->
    <div class="submit_contain">
      <el-button
        size="medium"
        style="width:100px"
        @click="$router.push({ path: '/manageSuperMember' })"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        size="medium"
        @click="submit"
        style="width:100px"
      >
        保存
      </el-button>
    </div>

    <!-- 选择展示的权益商品 -->
    <available
      v-if="availableStatus"
      @availableval="availableval"
      :dialogstatus.sync="availableStatus"
      :availableList="availableList"
      :availableSelect="availableSelect"
      :limit="10"
    ></available>

    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="networkschoolSelectStatus = false"
      :goodList="goodsList"
      :goodlistType="false"
      :goods_class="1"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="14"
      :hadMember="hadMember"
      :limit="limit"
    ></networkschoolSelect>
    <!--单选表单--->
    <el-dialog
      title="单选表单（请至少保留两个选项）"
      width="800px"
      :visible.sync="customForm2"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <div class="tuiguangPage flex-center" style="margin: -20px 50px 0 0;">
        <span class="star">*</span>
        <span class="select-course">表单项名称</span>
        <el-input
          class="box"
          placeholder="请输入表单项名称"
          maxlength="20"
          show-word-limit
          v-model="question.qname"
        ></el-input>
      </div>
      <div
        class="tuiguangPage flex-center"
        v-for="(item, index) in question.option_content"
        :key="index"
      >
        <span class="star">*</span>
        <span class="select-course">选项</span>
        <el-input
          class="box"
          placeholder="请输入选项内容"
          maxlength="20"
          show-word-limit
          v-model="question.option_content[index]"
        ></el-input>
        <el-button
          type="text"
          class="ml20"
          style="color:#FF3535"
          @click="deleteOptionContent(index)"
        >
          删除
        </el-button>
      </div>
      <div class="tuiguangPage flex-center">
        <span class="star"></span>
        <span class="select-course"></span>
        <div @click="question.option_content.push('')" class="prompt-text">
          + 增加选项
        </div>
      </div>
      <div class="tuiguangPage flex-center">
        <span class="star"></span>
        <span class="select-course">样式选择</span>
        <div class="flex-center mr20">
          <el-radio v-model="question.style" label="1">普通单选</el-radio>
          <el-popover
            popper-class="popoverBackB"
            placement="top-start"
            trigger="hover"
            :close-delay="1000"
            class="prompt-text"
          >
            <img src="@ass/img/mg_ptdx.png" />
            <div slot="reference">查看示例</div>
          </el-popover>
        </div>
        <div class="flex-center">
          <el-radio v-model="question.style" label="2">下拉框单选</el-radio>
          <el-popover
            popper-class="popoverBackB"
            placement="top-start"
            trigger="hover"
            :close-delay="1000"
            class="prompt-text"
          >
            <img src="@ass/img/mg_lbdx.png" />
            <div slot="reference">查看示例</div>
          </el-popover>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="toggleCustomForm2">取消</el-button>
        <el-button type="primary" @click="saveQuestion2">确定</el-button>
      </div>
    </el-dialog>
    <!-- 分组选择 -->
    <group-list
      v-if="groupstatus"
      :limit="tagGroupLint"
      @selectionChange="selectionChange"
      :hadselectList="hadselectList"
      :dialogstatus.sync="groupstatus"
    ></group-list>
  </div>
</template>

<script>
import editor from '@cm/editor'
import available from './available'
import changePhotoimg from '@cm/base/changePhotoimg'
import groupList from '@cm/base/groupList'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
export default {
  name: 'newSupermember',

  components: {
    networkschoolSelect,
    groupList,
    changePhotoimg,
    available,
    editor,
  },

  data() {
    return {
      style2: '',

      // 商品权益是否有会员选择
      hadMember: true,

      // 权益展示
      availableStatus: false,
      //  权益商品列表
      availableList: [],
      //  已选择的权益数据
      availableSelect: [],

      // 分组限制数量
      tagGroupLint: 10,
      // 分组弹窗状态
      groupstatus: false,

      //   已选择的分组
      hadselectList: [],

      // 单选表单弹窗状态
      customForm: false,
      customForm2: false,

      //  选择商品列表
      goodsList: [],
      //   选择商品弹窗状态
      networkschoolSelectStatus: false,

      // 购买权益分页
      currentPage1: 1,
      //   免费权益粉衣
      currentPage2: 1,

      //  提交数据列表
      ruleForm: {
        // 选择的整体风格的id
        selectSpecListType: 1,
        buyGoodsList: [],
        freeGoodsList: [],
        buyProductsList: [],
        freeProductsList: [],
        name: '',
        subtitle: '',
        validityType: '1',
        is_survey: '1',
        is_buy_equity: '2',
        is_free_equity: '2',
        buy_equity_name: '',
        buy_equity_type: '1',
        free_equity_type: '1',
        free_equity_name: '',
        style_img: '111',
        cover_type: '1',
        cover_img: '',
        display_content: ['1', '2', '3'],
        questionList: [
          {
            qname: '手机号',
            id: Math.random(),
            qattr: 1,
            not_null: '1',
            is_verification: '1',
            qtype: 1,
            is_sync: '1',
            default: 1,
          },
          {
            qname: '真实姓名',
            id: Math.random(),
            qattr: 2,
            not_null: '2',
            qtype: 1,
            is_sync: '1',
            is_hide: '2',
            default: 2,
          },
        ],
      },

      questionListCopy: [
        {
          qname: '手机号',
          id: Math.random(),
          qattr: 1,
          not_null: '1',
          is_verification: '1',
          qtype: 1,
          is_sync: '1',
          default: 1,
        },
        {
          qname: '真实姓名',
          id: Math.random(),
          qattr: 2,
          not_null: '2',
          qtype: 1,
          is_sync: '1',
          is_hide: '2',
          default: 2,
        },
      ],

      editId: '',

      limit: 100,

      //   单选表单的内容
      question: {
        qname: '',
        not_null: '1',
        type: '',
        option_content: ['', ''],
        style: '1',
      },

      // 整体风格数据
      spec: [
        {
          id: 1,
          bgImg: 'https://f.dingdingkaike.com.cn/teacher/img_hyfg01@2x.png',
          bgColor: '#343C71',
          titleC: '#909ADF',
          subtitleC: '#5563A1',
          equityC: '#5F6FB5',
          coumstImg: '',
        },
        {
          id: 2,
          bgImg: 'https://f.dingdingkaike.com.cn/teacher/img_hyfg02@2x.png',
          bgColor: '#EBA24E',
          titleC: '#9F5A0A',
          subtitleC: '#A67236',
          equityC: '#C1751E',
          coumstImg: '',
        },
        {
          id: 3,
          bgImg: 'https://f.dingdingkaike.com.cn/teacher/img_hyfg03@2x.png',
          bgColor: '#5874D5',
          titleC: '#C8D5FF',
          subtitleC: '#A9B7E3',
          equityC: '#C8D5FF',
          coumstImg: '',
        },
        {
          id: 4,
          bgImg: 'https://f.dingdingkaike.com.cn/teacher/img_hyfg04@2x.png',
          bgColor: '#1FE7C1',
          titleC: '#2FA38D',
          subtitleC: '#2FA38D',
          equityC: '#2FA38D',
          coumstImg: '',
        },
      ],
      //   整体风格选中项
      selectSpecList: {},
    }
  },

  filters: {
    getGoodtext(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 5:
          text = '公开课'
          break
        case 6:
          text = '电子资料'
          break
        case 7:
          text = '会员'
          break
        case 8:
          text = '知识商品'
          break
        case 9:
          text = '图文'
          break
      }

      return text
    },
    getgoodType(val) {
      let endVal = null
      if (val.course_id) {
        switch (Number(val.course_type)) {
          case 1:
            endVal = 1
            break
          case 2:
            endVal = 2
            break
          case 3:
            endVal = 3
            break
          default:
            endVal = 4
            break
        }
      } else if (val.open_class_id) {
        endVal = 5
      } else if (val.ed_id) {
        endVal = 6
      } else if (val.user_id) {
        endVal = 7
      } else if (val.live_goods_id) {
        endVal = 8
      } else if (val.graphic_id) {
        endVal = 9
      }
      return endVal
    },
  },

  created() {
    this.selectSpecList = this.spec[0]
    if (this.$route.query.id != 'new') {
      this.getdata()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑超级会员'
    }

    if (this.m_id != 'new') {
      this.getdata()
    }
  },

  computed: {
    m_id() {
      return this.$route.query.id
    },
  },

  methods: {
    changcoverType() {
      if (this.ruleForm.cover_type == 1) {
        this.selectSpecList.coumstImg = ''
      } else {
        this.selectSpecList.coumstImg = this.ruleForm.cover_img
      }
    },

    // 编辑-数据获取
    async getdata() {
      const { data } = await this.$http({
        url: `/member/setMember?m_id=${this.m_id}`,
      })

      this.datalist = _.assign({}, data)

      this.selectSpecList = this.spec[data.selectSpecListType - 1]

      this.ruleForm = _.assign({}, data)

      if (data.cover_type == 1) {
        this.ruleForm.cover_img = ''
      } else {
        this.selectSpecList.coumstImg = this.ruleForm.cover_img
      }
      if (this.ruleForm.is_survey == 2) {
        this.ruleForm = _.assign({}, this.ruleForm, {
          questionList: this.questionListCopy,
        })
      }

      console.log(this.ruleForm)
    },

    // 保存
    async submit() {
      this.showType = undefined
      if (!this.ruleForm.name) {
        this.$root.prompt('【基本信息】请输入会员卡名称！')
        return
      }

      if (this.ruleForm.validityType == 2 && !this.ruleForm.validity) {
        this.$root.prompt('【基本信息】请输入自定义天数！')
        return
      }

      if (this.ruleForm.is_buy_equity == 1) {
        if (!this.ruleForm.buy_equity_name) {
          this.$root.prompt('【会员权益】请输入购买权益名称！')
          return
        }
        if (
          this.ruleForm.buy_equity_type == 2 &&
          this.ruleForm.buyGoodsList.length == 0
        ) {
          this.$root.prompt('【会员权益】请选择购买权益商品！')
          return
        }

        // if (this.ruleForm.buyProductsList.length == 0) {
        //   this.$root.prompt('【展示设置】请选择购买权益展示商品！')
        //   return
        // }
      }

      if (this.ruleForm.is_free_equity == 1) {
        if (!this.ruleForm.free_equity_name) {
          this.$root.prompt('【会员权益】请输入免费权益名称！')
          return
        }
        if (
          this.ruleForm.free_equity_type == 2 &&
          this.ruleForm.freeGoodsList.length == 0
        ) {
          this.$root.prompt('【会员权益】请选择免费权益商品！')
          return
        }
        // if (this.ruleForm.freeProductsList.length == 0) {
        //   this.$root.prompt('【展示设置】请选择免费权益展示商品！')
        //   return
        // }
      }

      if (this.ruleForm.cover_type == 2 && !this.ruleForm.cover_img) {
        this.$root.prompt('【展示设置】请选择自定义图片！')
        return
      }

      if (this.ruleForm.cover_type == 1) {
        this.ruleForm = _.assign({}, this.ruleForm, {
          cover_img: this.selectSpecList.bgImg,
          selectSpecListType: this.selectSpecList.id,
        })
      } else {
        this.ruleForm = _.assign({}, this.ruleForm, {
          selectSpecListType: this.selectSpecList.id,
        })
      }

      if (this.m_id == 'new') {
        await this.$http({
          url: '/member/add',
          data: _.assign({}, this.ruleForm),
          successMsg: true,
        })
      } else {
        await this.$http({
          url: '/member/setMember',
          data: _.assign({}, this.ruleForm),
          successMsg: true,
        })
      }

      this.$router.push({ path: '/manageSuperMember' })
    },

    //权益切换使用商品类型
    equityTypeChange(val) {
      if (val == 1) {
        this.ruleForm.buyProductsList = []
      } else {
        this.ruleForm.freeProductsList = []
      }
      this.showType = undefined
    },

    // 选择展示权益
    showCourse(val) {
      this.showType = val
      this.limit = 10
      if (
        (this.ruleForm.buy_equity_type == 2 && val == 1) ||
        (this.ruleForm.free_equity_type == 2 && val == 2)
      ) {
        if (val == 1) {
          this.availableList = this.ruleForm.buyGoodsList
          this.availableSelect = this.ruleForm.buyProductsList

          this.hadMember = true
        } else {
          this.availableList = this.ruleForm.freeGoodsList
          this.availableSelect = this.ruleForm.freeProductsList
          this.hadMember = false
        }

        this.$nextTick(() => {
          this.availableStatus = true
        })
      } else {
        if (val == 1) {
          this.hadMember = true
          this.goodsList = this.ruleForm.buyProductsList
        } else {
          this.hadMember = false
          this.goodsList = this.ruleForm.freeProductsList
        }
        this.networkschoolSelectStatus = true
      }
    },

    // 权益商品展示选择回调
    availableval(val) {
      if (this.showType == 1) {
        this.ruleForm.buyProductsList = val
      } else {
        this.ruleForm.freeProductsList = val
      }
      this.showType = undefined
      this.availableStatus = false
    },

    // 图片结果
    complete(val) {
      this.ruleForm.cover_img = val
      const index = this.spec.findIndex(
        item => item.id == this.selectSpecList.id
      )
      this.spec[index] = _.assign({}, this.spec[index], { coumstImg: val })
      this.selectSpecList.coumstImg = val
      this.datalist = _.assign({}, this.ruleForm)
    },

    // 选择图片
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },

    // 选择风格
    selectSpec(item) {
      this.selectSpecList = _.assign({}, item, {
        coumstImg: this.selectSpecList.coumstImg,
      })
    },

    // 选择课程
    bugFreeCourse(val) {
      this.showType = undefined
      this.powerType = val
      if (val == 1) {
        this.goodsList = this.ruleForm.buyGoodsList
        this.hadMember = true
      } else {
        this.goodsList = this.ruleForm.freeGoodsList
        this.hadMember = false
      }
      this.limit = 100
      this.networkschoolSelectStatus = true
    },

    // 设置分组
    setgroup(val, type) {
      this.groupSettingIndex = val
      this.selecttype = type
      if (type == 1) {
        this.hadselectList = this.ruleForm.buyGoodsList[
          this.groupSettingIndex
        ].groups
      } else {
        this.hadselectList = this.ruleForm.freeGoodsList[
          this.groupSettingIndex
        ].groups
      }

      this.$nextTick(() => {
        this.groupstatus = true
      })
    },

    // 移除选择的课程
    delCourse(index, type) {
      if (type == 1) {
        this.ruleForm.buyGoodsList.splice(index, 1)
      } else {
        this.ruleForm.freeGoodsList.splice(index, 1)
      }
    },

    selectionChange(val) {
      if (this.selecttype == 1) {
        this.ruleForm.buyGoodsList[this.groupSettingIndex] = _.assign(
          {},
          this.ruleForm.buyGoodsList[this.groupSettingIndex],
          {
            groups: val,
          }
        )
      } else {
        this.ruleForm.freeGoodsList[this.groupSettingIndex] = _.assign(
          {},
          this.ruleForm.freeGoodsList[this.groupSettingIndex],
          {
            groups: val,
          }
        )
      }

      this.groupstatus = false
    },

    // 选择的商品
    singleselectcontentval(val) {
      val.map(item => {
        item.id = Math.random()
        item.good_type = this.$options.filters.getgoodType(item)
        item.groups = []
        return item
      })

      //   判断是否是在选择权益展示商品
      if (this.showType) {
        if (this.showType == 1) {
          this.ruleForm.buyProductsList.push(...val)
        } else {
          this.ruleForm.freeProductsList.push(...val)
        }
      } else {
        if (this.powerType == 1) {
          this.ruleForm.buyGoodsList.push(...val)
        } else {
          this.ruleForm.freeGoodsList.push(...val)
        }
      }

      this.showType = undefined
    },

    // 已选择的购买权益商品移除
    delbuyProducts(index, type) {
      if (type == 1) {
        this.ruleForm.buyProductsList.splice(index, 1)
      } else {
        this.ruleForm.freeProductsList.splice(index, 1)
      }
    },

    // 购买免费权益商品上下移动
    buyProductsUpDown(index, newIndex, type) {
      let optionlist = ''
      if (type == 1) {
        optionlist = this.ruleForm.buyProductsList
      } else {
        optionlist = this.ruleForm.freeProductsList
      }
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },

    // 上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.ruleForm.questionList
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },

    // 编辑表单
    edit(val) {
      if (val.id == this.question.id) return
      this.question = _.merge({}, val)
      this.editId = this.question.id
      // 区分填空 还是 单选
      if (this.question.qtype == 1) {
        this.toggleCustomForm1()
      } else {
        this.toggleCustomForm2()
      }
    },

    // 切换隐藏和显示
    changehiden(index, val) {
      this.ruleForm.questionList[index].is_hide = val
    },

    // 删除题目
    removeQuestion(index) {
      this.ruleForm.questionList.splice(index, 1)
    },

    // 删除单选表单
    deleteOptionContent(index) {
      if (this.question.option_content.length <= 2) {
        this.$root.prompt({
          msg: '不可删除，请至少保留两个选项！',
          type: 'warning',
        })
      } else {
        this.question.option_content.splice(index, 1)
      }
    },

    // 填空表单
    toggleCustomForm1() {
      this.customForm = !this.customForm
      if (this.customForm) {
        // 设置为填空属性
        this.question.qtype = 1
        this.$nextTick(() => {
          this.$refs.questionInput.focus()
        })
      } else {
        this.question = {
          qname: '',
          not_null: '1',
          qtype: '',
          option_content: ['', ''],
          style: '1',
        }
        this.editId = ''
      }
    },

    // 单选表单
    toggleCustomForm2() {
      this.customForm2 = !this.customForm2
      if (this.customForm2) {
        // 设置为单选属性
        this.question.qtype = 2
      } else {
        this.dialogClose()
      }
    },

    // 关闭弹窗
    dialogClose() {
      this.question = {
        qname: '',
        not_null: '1',
        qtype: '',
        option_content: ['', ''],
        style: '1',
      }
      this.editId = ''
    },

    // 单选加选项
    saveQuestion2() {
      if (!this.question.qname.trim()) {
        this.$root.prompt('请输入表单项名称')
        return
      } else if (this.question.option_content.some(v => !v.trim())) {
        this.$root.prompt('请输入选项内容')
        return
      }
      const res = _.merge({}, this.question, {
        qname: this.question.qname,
        id: Math.random(),
        qattr: 3,
      })
      if (this.editId) {
        const index = this.ruleForm.questionList.findIndex(
          item => item.id == this.editId
        )
        this.ruleForm.questionList.splice(index, 1, res)
      } else {
        this.ruleForm.questionList.push(res)
      }
      this.toggleCustomForm2()
    },

    // 填空加选项
    saveQuestion() {
      if (this.question.qname) {
        const res = _.merge({}, this.question, {
          qname: this.question.qname,
          id: Math.random(),
          qattr: 3,
        })
        if (this.editId) {
          const index = this.ruleForm.questionList.findIndex(
            item => item.id == this.editId
          )
          this.ruleForm.questionList.splice(index, 1, res)
        } else {
          this.ruleForm.questionList.push(res)
        }
        this.toggleCustomForm1()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.newSupermember {
  padding-top: 15px;
  position: relative;
  background-color: #fff;
  padding: 20px;

  .forgood {
    margin-bottom: 40px;
  }
  .submit_contain {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-bottom: 10px;
    background-color: #fff;
    text-align: center;
    z-index: 2;
    transform: translateX(50px);
  }
  .student_views {
    position: fixed;
    right: 40px;
    top: 100px;
    z-index: 4;
    .contain {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: absolute;
      left: 17px;
      top: 70px;
      height: 398px;
      width: 242px;
      .top {
        position: relative;
        height: 151px;
        .img {
          height: 134px;
          width: 100%;
        }
        .name_subtitle {
          position: absolute;
          top: 35px;
          left: 18px;
          width: 207px;
          height: 115px;
          border-radius: 6px;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.19);
          .name {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 167px;

            .sub_title {
              font-size: 13px;
              margin: 3px 0 0;
            }
          }
          .quanyi {
            margin-top: 15px;
          }
          .img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .produce_show {
        flex: 1;
        overflow: auto;
        @extend %noScroll;
        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 0 10px;
          .item {
            margin-bottom: 10px;
            width: 107px;
            padding-bottom: 12px;
            background: #ffffff;
            border-radius: 3px;
            border: 1px solid #f8f8f8;
            .img {
              width: 107px;
              height: 60px;
            }
            .course_name {
              font-size: 9px;
              font-weight: 600;
              color: #333333;
              line-height: 1.5;
              margin: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .type {
              font-size: 7px;
              margin-left: 10px;
              font-weight: 400;
              color: #999999;
              line-height: 7px;
            }
          }
        }
        .top_title {
          display: flex;
          align-items: center;
          justify-content: center;
          .line {
            width: 43px;
            height: 1px;
            background: #eaeaea;
          }
          .tip {
            font-size: 8px;
            font-weight: 400;
            color: #333333;
            margin: 20px 12px 13px;
          }
        }
        ::v-deep .editor-html {
          color: #333;
          padding: 0 12px;
          font-size: 14px;
          line-height: 1.5;
          word-break: break-all;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  ::v-deep .el-table__header-wrapper {
    line-height: 18px;
  }
  .numbertip {
    font-size: 14px;
    color: #666666;
    margin-left: 20px;
  }
  .coustom_contain {
    display: flex;
    align-items: center;
    .tu {
      width: 160px;
      height: 90px;
      background: #f5f5f5;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      .tip {
        font-size: 14px;
        color: #333333;
        margin-top: 33px;
        line-height: 1.5;
      }
    }
  }
  .changePhotoimg {
    height: 0 !important;
    width: 0 !important;
    position: absolute;
  }
  .spec {
    display: flex;
    align-items: center;
    .sp_item {
      cursor: pointer;
      width: 82px;
      height: 46px;
      margin-right: 20px;
      position: relative;
      img {
        width: 100%;
        width: 100%;
      }
      .selected {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 30px;
        width: 30px;
      }
    }
  }
  ::v-deep .redcolor {
    .el-button--text {
      color: #ff3535;
    }
    .el-button--text:active,
    .el-button--text:hover,
    .el-button--text:focus {
      color: #ff3535;
    }
  }
  .good_contain {
    position: relative;
    width: 700px;
    ::v-deep .el-pagination {
      transform: scale(0.85);
      transform-origin: center;
      position: absolute;
      right: 0;
      margin-top: 10px;
    }
  }
  .prompt-text {
    font-size: 14px;
    color: rgb(10, 162, 155);
    cursor: pointer;
  }

  .star {
    color: #ff3535;
    margin-right: 10px;
    font-size: 14px;
  }
  .select-course {
    width: 100px;
    font-size: 14px;
  }
  .footerHasCourse {
    margin-top: 40px;
    text-align: center;
    padding-bottom: 20px;
  }
  .footerNoCourse {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    // margin: auto;
  }
  .tuiguangPage {
    margin-top: 30px;
    display: flex;
  }
  ::v-deep .ruleFormclass {
    .contain {
      display: flex;

      .type {
        margin-left: 10px;
        align-items: center;
        display: flex;
        font-size: 12px;
        color: #666666;

        img {
          width: 8px;
          height: 7px;
          margin-right: 6px;
        }

        span {
          font-size: 12px;
          color: #666666;
        }
      }
      .tu {
        width: 66px;
        height: 46px;
        background: #d8d8d8;
        margin-right: 14px;
        flex-shrink: 0;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .right {
        flex: 1;
        overflow: hidden;
        .itemtitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: #333333;
        }
        .msg {
          display: flex;
          align-items: center;
          width: 500px;
          justify-content: space-between;

          .money {
            font-size: 14px;
            color: #ff3535;
          }
          .cross_money {
            font-size: 14px;
            color: #999999;
            margin: 0 30px 0 15px;
            text-decoration: line-through;
          }
          .course_type {
            font-size: 14px;
            color: #333333;
            margin-left: 30px;
          }

          .del {
          }
        }
      }
    }

    .form-input {
      margin: 10px 0 8px 0;
      border-bottom: 1px solid #ff3535;
      .el-input__inner {
        height: 54px;
        border: 0;
        padding-left: 0;
        line-height: 54px;
      }
    }
    .norequire {
      & .el-form-item__label {
        padding-left: 10px;
      }
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #333333;
    }
    .el-radio__label {
      display: inherit;
    }
    .form-box {
      width: 727px;
      margin-top: 20px;
      .space {
        color: $color;
        font-size: 14px;
        margin: 0 10px;
      }
      .form-item {
        border-bottom: 1px solid #eaeaea;
        .left {
          flex: 1;
          .required {
            color: #ff3535;
            margin-right: 5px;
            vertical-align: top;
            font-size: 14px;
          }
          .title {
            font-size: 14px;
            color: #666666;
          }
          .dx-switch {
            margin-left: 20px;
          }
          .input {
            margin-top: 10px;
            ::v-deep .el-input__inner {
              height: 54px;
              border: 0;
              padding-left: 0;
              line-height: 54px;
            }
          }
        }
      }
      .option1 {
        font-size: 14px;
        color: #666666;
        margin: 0 0 14px 10px;
      }
      .option2 {
        width: 420px;
        display: block;
        margin: 10px 0 20px 10px;
      }
    }

    .titletop {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 30px;
    }
  }
}
</style>
